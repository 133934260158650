export const FirebaseConfig = {
	"projectId": "yumyum-taxi",
	"appId": "1:795628520140:web:582ae88369567a1137f869",
	"databaseURL": "https://yumyum-taxi-default-rtdb.firebaseio.com",
	"storageBucket": "yumyum-taxi.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCAPM5JCfukIFVHmsl6Xo9enwwUz686l_U",
	"authDomain": "yumyum-taxi.firebaseapp.com",
	"messagingSenderId": "795628520140",
	"measurementId": "G-KQG3RK5VV9"
};